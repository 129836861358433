<template>
	<div class="container flex f-column f-align" @click="$refs.inputRef.focus()">
		<div class="flex f-center f-justifyB w100">
			 <div class="flex f-column f-align">
			<span class="font60 black bold">微信扫码下单</span>
			<div class="code-box f-center" @click="getImgCode()">
				<img v-if="imgCode" :src="imgCode" style="width: 19rem;height: 19rem;border-radius: 50px;">
			</div>
		</div>
			<!-- <div class="flex f-column f-align">
				<span class="font60 black bold">微信扫码下单</span>
				<div class="code-box f-center">
					<qriously :value=" pinjie + gzbh" :size="getSize()" />
				</div>
			</div> -->
		</div>
		<span class="font30 black" style="margin-top: 40px;">扫描青柠智能洗衣平台进入微信扫码下单</span>
		<div class="bottom-gzbh flex f-column">
			<span>机柜地址：{{address}}</span>
			<div class="flex">
				<span>机柜编号：{{gzbh}}</span>
				<span class="ml20">客服电话：{{phone}}</span>
			</div>
		</div>

		<input ref="inputRef" class="input-ref" v-model="str" />
	</div>
</template>
<script>
	export default {
		props: {
			gzbh: String,
			phone: String,
			address: String
		},
		
		data() {
			return {
        pinjie:'https://xx.dz888.shop/shopadd?pinjie=',
				str: '',
				isScan: true ,// 默认可以扫描
				imgCode: localStorage.getItem('imgCode') || null
			};
		},
		mounted() {
			this.$emit('change', '')
			if (!this.imgCode) {
				this.getImgCode()
			}
			
			this.$refs.inputRef.focus()
			this.onkeyDownHandle()
		},
		methods: {
			getSize() {
				return 280*(document.documentElement.clientWidth / 1080) 
			},
			getImgCode() {
				this.$post("/wxPay/placeAnHomePageUrl", {
					gzbh: localStorage.getItem('gzbh'),
				}).then(res => {
					if (res.code == 200) { 
						localStorage.setItem('imgCode', res.data.img)
						this.imgCode = res.data.img
					}
				});
			},
			onkeyDownHandle() {
				let _this = this
				document.onkeydown = function(e) {
					if (_this.$route.path == '/scanCode') {
						let key = e.keyCode
						if (key == 13) {
							if (_this.str == '') return;
							if (!_this.isScan) return;
							_this.isScan = false
							_this.$post('/Order/deposit', {
								saveCode: _this.str,
								gzbh: localStorage.getItem('gzbh')
							}).then((res) => {
								_this.str = ''
								if (res.code == 200) {
									localStorage.setItem('type', 'user-cun')
									localStorage.setItem('xmbh', res.xmbh)
									localStorage.setItem('openCmd', res.openCmd)
									localStorage.setItem('portName', res.portName)
									_this.$router.push('./boxDoorOpen')
								} else {
									_this.$toast({
										message: res.msg,
										duration: 3000
									})
									_this.isScan = true
								}
							}).catch(() => {
								_this.isScan = true
							})
						}
					}
				}
			}
		}
	};
</script>
<style scoped lang="scss">
	.container {
		padding: 80px 96px 0 45px;
		height: 600px;
		position: relative;
		
		.code-box {
			width: 324px;
			height: 324px;
			border: 2px solid #000000;
			border-radius: 15px;
			margin-top: 26px;
		}
		
		.line {
			width: 0px;
			height: 320px;
			border: 2px dashed #333333;
			position: absolute;
			left: 500px;
			top: 190px;
		}
		
		.input-ref {
			position: fixed;
			left: -1000px;
			top: 0;
		}
	}
</style>
